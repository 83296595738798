.app__projects{

    width: 100%;
    height: 100%;
    /* overflow: hidden; */
}
.app__projects h2{
    font-size: 3rem;
    padding: 4rem 6rem;
    font-family: var(--font-base);
    font-weight: 600;
}
@media screen and (max-width: 1000px){
    .app__projects h2{
        padding: 2rem 4rem;
    }
}
@media screen and (max-width: 850px){
    .app__projects h2{
        text-align: center;
        font-size: 2.4rem;
    }
}
@media screen and (max-width: 550px){
    .app__projects h2{
        text-align: center;
        font-size: 2rem;
        padding: 2rem  0 0 0 ;
    }
}