.app__about {
  width: 100%;
  height: 100%;
}
.team-presentation {
  width: 100%;
  height: 100vh;
  background-color: #f8f5f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-presentation-box {
  width: 80%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-presentation-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1200px) {
  .team-presentation-box {
    width: 80%;
    height: auto;
  }
}
@media screen and (max-width: 1000px) {
  .team-presentation {
    height: 70vh;
  }
}
@media screen and (max-width: 850px) {
  .team-presentation {
    height: 60vh;
  }
}
@media screen and (max-width: 550px) {
  .team-presentation {
    height: 50vh;
  }
  .team-presentation-box {
    width: 90%;
  }
}
