.app__headText{
    margin-bottom: 3rem;
}
.app__headText-title{
    overflow: hidden;
}
.app__headText h1{
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 500;
    position: relative;
    padding-bottom: 1rem;
    color: black;
}
.app__headText h1::after{
    content: '';
    height: 4px;
    width: 70px;
    position: absolute;
    bottom: 0px;
    left: 0;
    background-color: var(--color-orange);
}
@media screen and (max-width: 800px){
    .app__headText h1{
        font-size: 2.3rem;
    }
}
@media screen and (max-width: 450px){
    .app__headText h1{
        font-size: 1.7rem;
    }
}