.app__serviceOBE {
  width: 100%;
  height: 160vh;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: #bbd0d1;
  padding: 4rem;
  overflow: hidden;
}
.serviceOBE_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__serviceOBE-box-one h3 {
  margin-bottom: 1rem;
}
.app__serviceOBE-box-one p,
.app__serviceOBE-box-one li {
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  opacity: 0.7;
  font-family: var(--font-alt);
}
.app__serviceOBE-box-one ul {
  padding-left: 1rem;
  margin-top: 1rem;
}
.app__serviceOBE-box-one ul span {
  padding-left: 0.5rem;
}
.app__serviceOBE-box-one {
  grid-column: 1 /3;
  grid-row: 1 /2;
}
.app__serviceOBE-box-two {
  grid-column: 5 /6;
  grid-row: 1 /1;
}
.app__serviceOBE-box-three {
  grid-column: 1 /2;
  grid-row: 2 /3;
}
.app__serviceOBE-box-four {
  grid-column: 3 /4;
  grid-row: 2 /3;
}
.app__serviceOBE-box-five {
  grid-column: 4 /5;
  grid-row: 2 /3;
}
.app__serviceOBE-box-six {
  grid-column: 2 /3;
  grid-row: 3 /4;
}
.app__serviceOBE-box-seven {
  grid-column: 3 /4;
  grid-row: 3 /4;
}
.app__serviceOBE-box-hight {
  grid-column: 5/6;
  grid-row: 3 /4;
}
@media screen and (max-width: 1200px) {
  .app__serviceOBE {
    width: 100%;
    height: 100vh;
    display: grid;
    gap: 1.5rem;
    background-color: #bbd0d1;
    padding: 4rem;
  }
  .app__serviceOBE-box-one {
    grid-column: 1 /5;
    grid-row: 1 /2;
  }
}
@media screen and (max-width: 800px) {
  .app__serviceOBE {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    padding: 2rem;
  }
}
