.app__specialization{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #fff;
}
.app__specialization-left-box{
    flex: 1;
    padding-right: 5rem;
    padding-left: 2rem;
}
.app__specialization-right-box{
    flex: 1;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
}
.app__specialization-right-box p{
    font-size: 1.2rem;
    line-height: 1.5;
    color: #00000098;
}
.app__specialization-categories{
    display: flex;
    flex-wrap: wrap;
    padding-top: 2rem;
    width: 100%;
    gap: 1rem;
}
.app__specialization-category{
    width: 47%;
    height: auto;
}
.specialization-icons-text{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.app__specialization-category h3{
    font-weight: 600;
    color: black;
    font-size: 16px;
    font-family: var(--font-alt);
}
@media screen and (max-width: 1200px) {
    .app__specialization-left-box{
        padding-right: 0;
        padding-left: 0;
    }
    .app__specialization-right-box{
        padding-top: 0rem;
    }   
}
@media screen and (max-width: 900px) {
    .app__specialization{
        flex-direction: column-reverse;
        gap: 3rem;
        height: auto;
    }
    .app__specialization-left-box{
        flex: 1;
        padding-right: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
    }
    .app__specialization-right-box{
        padding-top: 0;
    }
}
@media screen and (max-width: 550px) {
    .app__specialization-category{
        width: 100%;
    }  
}