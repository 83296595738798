.app__navbar {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  left: 0;
  border-bottom: 1px solid #ffffff2c;
  z-index: 5;
}
.navbar-gb {
  background-color: #1e181094;
}
.navbar-gb li {
  color: #000;
}
.app__navbar-logo {
  width: fit-content;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 6rem;
}
.app__navbar-logo-img {
  width: 50px;
  height: 50px;
}
.app__navbar-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__navbar-logo h2 {
  color: #fff;
  font-family: var(--font-alt);
}
.app__navbar-links-container {
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.app__navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  list-style: none;
  padding-right: 4rem;
}
.app__navbar-links li a {
  font-size: 1.2rem;
  font-family: var(--font-alt);
  font-weight: 600;
}

.app__navbar-smallScreen_overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: black;
  overflow: hidden;
  z-index: 10;
}
.smallSreen-headLine {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
}
.smallSreen-headLine img {
  height: 70px;
  aspect-ratio: 1/1;
  /* height: 100%; */
  object-fit: cover;
  margin-left: 1rem;
}
.app__navbar-smallScreen-links {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  padding-top: 100px;
}
.app__navbar-smallScreen-links li {
  list-style: none;
}
.app__navbar-smallScreen-links li a {
  font-size: 1.5rem;
  font-family: var(--font-base);
  font-weight: 600;
  color: #fff;
  line-height: 2;
}
.app__navbar-smallScreen-links li a:hover {
  color: var(--color-orange);
}
.app__navbar-smallScreen {
  display: none;
}
@media screen and (max-width: 1000px) {
  .app__navbar-logo {
    padding-left: 4rem;
  }
}
@media screen and (max-width: 800px) {
  .app__navbar-links-container {
    display: none;
  }
  .app__navbar-logo {
    width: 200px;
    flex: 0.5;
    padding-left: 2rem;
  }
  .app__navbar-logo h2 {
    font-size: 1.2rem;
  }
  .app__navbar-smallScreen {
    display: block;
  }
}
@media screen and (max-width: 550px) {
  .app__navbar-logo {
    flex: 1;
    padding-left: 0.5rem;
  }
  .app__navbar-logo h2 {
    font-size: 1rem;
  }
}
